.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
    bottom: auto;
    top: 35px;
}

.tags {
    color: turquoise;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore';
}

.botoom-tag-html {
    margin-left: -20px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 500px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;

}

.about-page, .contact-page, .project-card {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
         
        h1{
            font-size: 53px;
            font-family: 'Coolvetica';
            color: turquoise;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;

            &::before {
                content: '<h1>';
                font-family: 'La Belle Aurore';
                font-size: 18px;
                position: absolute;
                margin-top: -10px;
                left: -10px;
                opacity: 0.6;
                line-height: 18px;
            }

            &::after {
                content: '<h1/>';
                font-family: 'La Belle Aurore';
                font-size: 18px;
                line-height: 18px;
                position: absolute;
                left: -80px;
                bottom: -20px;
                margin-left: 20px;
                opacity: 0.6;

            }
        }

            p {
                font-size: 12px;
                color: white;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                font-weight: 300;
                min-width: fit-content;
                animation: pulse 1s;

                &:nth-of-type(1) {
                    animation-delay: 1.1s;
                }

                &:nth-of-type(2) {
                    animation-delay: 1.2s;
                }

                &:nth-of-type(3) {
                    animation-delay: 1.3s;
                }
            }
    }
    .text-animate-hover {
        &:hover{
            color: #fff;
            


        }
    }
}

