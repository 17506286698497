.project-card {
    position: absolute;
    background: #000;
    top: 20%;
    right: 10%;
    left: 10%;
    width: 60%;
    height: 50%;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;
}